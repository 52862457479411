import { GraphQLData } from "interfaces/graphql-data";
import { ITenant } from "interfaces/tenant";

export class GetTransactionIdModel implements GraphQLData {
  lastEvaluatedKey: string = '';
  transactionId: string = '';

  public static create(
    transactionId: string) : GetTransactionIdModel {
    const t = new GetTransactionIdModel();
    t.transactionId = transactionId;
    return t;
  }

  async hydrate(data: any, tenant: ITenant): Promise<void> {
    //..
    // data.data.GetTenant.GetUpdatePaymentTransactionId (string)
    console.log(data);
    if (data && data.data && data.data.GetTenant && data.data.GetTenant.GetUpdatePaymentTransactionId) {
      this.transactionId = data.data.GetTenant.GetUpdatePaymentTransactionId;
    }
  }
}