import { useEffect } from 'react';
import usePaddle from '../utilities/paddle-helper';
import { useHistory } from 'react-router-dom';

const Payment = () => {
  const { paddle, billingEnabled, checkoutClosed } = usePaddle();
  const history = useHistory();
  useEffect(() => {
    if(checkoutClosed)
      history.push('/');
  }, [checkoutClosed])
  return (
    <div>
      {!billingEnabled && <span style={{color: 'white'}}>Billing is not enabled</span>}
    </div>
  )
}

export default Payment;
