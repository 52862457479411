import { MessageTypeCache } from "cache/MessageTypeCache";
import { EchoFunctionType, IEchoFunction } from "interfaces/echo-function";
import { GraphQLData } from "interfaces/graphql-data";
import { ITenant } from "interfaces/tenant";
import { AtByModel } from "models/at-by-model";
import { MessageTypeModel } from "models/message-type/messagetype-model";
import { TenantModel } from "models/tenant/tenant-model";
import { BitmapperFunctionModel } from "./bitmapper-function-model";
import { FunctionModel } from "./function-model";
import { ProcessorFunctionModel } from "./processor-function-model";
import { ApiAuthenticatorFunctionModel } from "./api-authenticator-function-model";

export class FunctionListModel implements GraphQLData {
  lastEvaluatedKey: string = '';
  functions: Array<IEchoFunction> = []

  async hydrate(data: any, tenant: ITenant): Promise<void> {
    if (data && data.data && data.data.ListFunctions) {
      const dt = data.data.ListFunctions;
      this.lastEvaluatedKey = dt.lastEvaluatedKey;
      const messageTypes = await new MessageTypeCache().getMessageTypes(tenant);
      this.functions = dt.echos.map(((o: { 
        argumentMessageType: {
          auditor: string,
          bitmapperTemplate: string,
          created: { at: string, by: string }, 
          description: string,
          lastModified: { at: string, by: string },
          name: string,
          readme: string,
          requirements: string[],
          system: boolean,
          tenant: { name: string, description: string, region: string, active: boolean, audit: boolean },
          processorTemplate: string
        },
        code: string,
        created: { at: string, by: string },
        description: string,
        name: string,
        readme: string,
        lastModified: { at: string, by: string },
        requirements: string[],
        system: boolean,
        tenant: { name: string, description: string, region: string, active: boolean, audit: boolean },
        __typename: string,
        returnMessageType: {
          auditor: string,
          bitmapperTemplate: string,
          created: { at: string, by: string }, 
          description: string,
          lastModified: { at: string, by: string },
          name: string,
          readme: string,
          requirements: string[],
          system: boolean,
          tenant: { name: string, description: string, region: string, active: boolean, audit: boolean },
          processorTemplate: string
        },
        type: EchoFunctionType}) => {
        let tenant = TenantModel.create('', '', '', false,false);
        if (o.tenant && o.tenant.name) {
          tenant = TenantModel.create(o.tenant.name, o.tenant.description ? o.tenant.description : '', o.tenant.region ? o.tenant.region : '', o.tenant.active ? o.tenant.active : false, o.tenant.audit ? o.tenant.audit : false);
        }
        
        let argumentMessageType = null;
        if (o.argumentMessageType) {
          argumentMessageType = messageTypes.find(t => t.name === o.argumentMessageType.name);
        }

        if (o.type === EchoFunctionType.processor && !o.returnMessageType) {
          throw new Error(`Function: ${o.name} is a processor but has no return message type`);
        }

        let returnMessageType = null;
        if (o.returnMessageType) {
          returnMessageType = messageTypes.find(t => t.name === o.returnMessageType.name);
        }

        let tp = EchoFunctionType.bitmapper;
        if (o.__typename === 'ProcessorFunction') {
          tp = EchoFunctionType.processor;
        } else if (o.__typename === 'ApiAuthenticatorFunction') {
          tp = EchoFunctionType.apiauthenticator;
        }

        if (o.__typename === EchoFunctionType.bitmapper) {
          return BitmapperFunctionModel.create(
            argumentMessageType || new MessageTypeModel(),
            o.code,
            new AtByModel(o.created.at, o.created.by),
            o.description,
            o.name,
            o.readme,
            new AtByModel(o.lastModified.at, o.lastModified.by), 
            o.requirements,
            o.system,
            tenant,
            o.__typename,
            returnMessageType || new MessageTypeModel(),
            tp
          );
        } else if (o.__typename === EchoFunctionType.processor) {
          return ProcessorFunctionModel.create(
            argumentMessageType || new MessageTypeModel(),
            o.code,
            new AtByModel(o.created.at, o.created.by),
            o.description,
            o.name,
            o.readme,
            new AtByModel(o.lastModified.at, o.lastModified.by), 
            o.requirements,
            o.system,
            tenant,
            o.__typename,
            returnMessageType || new MessageTypeModel(),
            tp
          );
        } else if (o.__typename === EchoFunctionType.apiauthenticator) {
          return ApiAuthenticatorFunctionModel.create(
            o.code,
            new AtByModel(o.created.at, o.created.by),
            o.description,
            o.name,
            o.readme,
            new AtByModel(o.lastModified.at, o.lastModified.by), 
            o.requirements,
            o.system,
            tenant,
            o.__typename,
            tp
          );
        }
        return FunctionModel.create(
          argumentMessageType || new MessageTypeModel(),
          o.code,
          new AtByModel(o.created.at, o.created.by),
          o.description,
          o.name,
          o.readme,
          new AtByModel(o.lastModified.at, o.lastModified.by), 
          o.requirements,
          o.system,
          tenant,
          o.__typename,
          tp,
          returnMessageType || new MessageTypeModel()
        );
      }
    )
  );
    } else {
      throw new Error('data structured passed in does not contain data, data.data, data.data.ListFunctions');
    }
  }
}