import { GraphQLData } from "interfaces/graphql-data";
import { IMessageType } from "interfaces/message-type";
import { AtByModel } from "../at-by-model";
import { MessageTypeModel } from "./messagetype-model";
import { TenantModel } from "../tenant/tenant-model";
import { ITenant } from "interfaces/tenant";
export class MessageTypeListModel implements GraphQLData {
  lastEvaluatedKey: string = '';
  scannedCount: number = 0;
  messageTypes: Array<IMessageType> = []

  async hydrate(data: any, tenant: ITenant): Promise<void> {
    if (data && data.data && data.data.ListMessageTypes) {
      const dt = data.data.ListMessageTypes;
      this.lastEvaluatedKey = dt.lastEvaluatedKey;
      this.scannedCount = dt.scannedCount;
      this.messageTypes = dt.echos.map(((o: {
        auditor: string,
        bitmapperTemplate: string,
        created: { at: string, by: string },
        description: string,
        lastModified: { at: string, by: string },
        name: string,
        readme: string,
        requirements: string[],
        sampleMessage: string,
        system: boolean,
        tenant: { name: string, description: string, region: string, active: boolean, audit: boolean },
        processorTemplate: string}) => {
        let tenant = TenantModel.create('', '', '', false, false);
        if (o.tenant && o.tenant.name) {
          tenant = TenantModel.create(o.tenant.name, o.tenant.description ? o.tenant.description : '', o.tenant.region ? o.tenant.region : '', o.tenant.active ? o.tenant.active : false, o.tenant.audit ? o.tenant.audit : false);
        }
        const messageTypeModel = MessageTypeModel.create(
          o.auditor,
          o.bitmapperTemplate,
          new AtByModel(o.created.at, o.created.by),
          o.description,
          new AtByModel(o.lastModified.at, o.lastModified.by), 
          o.name,
          o.readme,
          o.requirements,
          o.sampleMessage,
          o.system,
          tenant,
          o.processorTemplate);
        return messageTypeModel;
      }));
    } else {
      throw new Error('data structured passed in does not contain data, data.data, data.data.ListMessageTypes');
    }
  }
}
