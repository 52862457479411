import { GraphQLData } from "interfaces/graphql-data";
import { ITenant } from "interfaces/tenant";
import { TenantModel } from "./tenant-model";
export class TenantListModel implements GraphQLData {
  lastEvaluatedKey: string = '';
  tenants: Array<ITenant> = []

  async hydrate(data: any, tenant: ITenant): Promise<void> {
    console.log("tenant-list-model", data.data);
    this.tenants = data.data.ListTenants.echos
      .filter((o: { name: string; description: string; region: string; active: boolean; audit: boolean, subscriptionId: string } | null) => o !== null)
      .map(
        (o: { name: string; description: string; region: string; active: boolean; audit: boolean, subscriptionId: string }) => TenantModel.create(o.name, o.description, o.region, o.active, o.audit || false, o.subscriptionId || '')
      );
  }
}

export const SortTenants = function(t1: ITenant, t2: ITenant) {
  if (t1.name < t2.name) return -1;
  if (t1.name > t2.name) return 1;
  return 0;
}