import { IUsage } from "interfaces/usage";
import { IUsageLineItem } from "interfaces/usage-line-item";
import { GraphQLData } from "interfaces/graphql-data";
import { ITenant } from "interfaces/tenant";
import { UsageModel } from "./usage-modal";

export class UsageListModel implements GraphQLData {
  usages: Array<IUsage> = [];
  lastEvaluatedKey: string = '';

  async hydrate(data: any, tenant: ITenant): Promise<void> {
    //..
    console.log(data);
    if (data && data.data && data.data.GetTenant && data.data.GetTenant.usages) {
      console.log(data.data.GetTenant.usages);
      const usages = data.data.GetTenant.usages
        .filter((o: IUsage) => o !== null)
        .map(
          (o: IUsage) => UsageModel.create(o.lineItems || [], o.month || 0, o.total || 0, o.year || 0)
      );
      this.usages = sortUsages(usages);
    } else {
      throw new Error('data structured passed in does not contain data, data.data, data.data.GetTenant or data.data.GetTenant.usages');
    }
  }
}

export const sortUsages = (items: Array<IUsage>) => {
  return items.sort((a, b) => {
    if (a.year < b.year) return 1;
    if (a.year > b.year) return -1;
    if (a.month < b.month) return 1;
    if (a.month > b.month) return -1;
    return 0;
  });
}