export class TypesStatic {
  public static atby = `
    at
    by
  `
  public static auditor = `
    created { ${TypesStatic.atby} }
    lastModified { ${TypesStatic.atby} }
  `

  public static paginator = `
    lastEvaluatedKey
  `


  public static tenant = `
    ${TypesStatic.auditor}
    name
    description
    region
    __typename
    active
    audit
    subscriptionId
  `

  public static nodeCommon = `
    ${TypesStatic.auditor}
    description
    name
    tenant { ${TypesStatic.tenant} }
    __typename
  `

  public static cognitoCredentials = `
    clientId
    password
    userPoolId
    username
  `
  public static mountRequirement = `
    description
    source
    target
  `

  public static portRequirement = `
    containerPort
    description
    protocol
  `

  public static messageType = `
    auditor
    ${TypesStatic.auditor}
    bitmapperTemplate
    description
    name
    requirements
    sampleMessage
    system
    tenant { ${TypesStatic.tenant} }
    processorTemplate
    readme
    __typename
  `

  public static messageTypeJustName = `
    name
    __typename
  `

  public static node = `
    ${TypesStatic.auditor}
    description
    name
    tenant { ${TypesStatic.tenant} }
    __typename
  `

  public static nodeJustName = `
    name
    __typename
  `

  public static edge = `
    ${TypesStatic.auditor}
    description
    messageCounts {
      approximateNumberOfMessages
      approximateNumberOfMessagesDelayed
      approximateNumberOfMessagesNotVisible
    }
    maxReceiveCount
    messageType { ${TypesStatic.messageType} }
    queue
    source { ${TypesStatic.node} }
    target { ${TypesStatic.node} }
    draining
  `

  public static edgeShort = `
    description
    messageType { ${TypesStatic.messageTypeJustName} }
    maxReceiveCount
    queue
    source { ${TypesStatic.nodeJustName} }
    target { ${TypesStatic.nodeJustName} }
    draining
    messageCounts {
      approximateNumberOfMessages
      approximateNumberOfMessagesDelayed
      approximateNumberOfMessagesNotVisible
    }
  `

  public static bitmapperFunction = `
    argumentMessageType { ${TypesStatic.messageType} }
    code
    ${TypesStatic.auditor}
    description
    name
    readme
    requirements
    system
    tenant { ${TypesStatic.tenant} }
    __typename
  `

  public static apiauthenticatorFunction = `
    tenant { ${TypesStatic.tenant} }
    name
    code
    description
    readme
    requirements
    ${TypesStatic.auditor}
    system
    __typename
  `

  public static processorFunctionJustNames = `
    name
    __typename
  `


  public static processorFunction = `
    argumentMessageType { ${TypesStatic.messageTypeJustName} }
    returnMessageType { ${TypesStatic.messageTypeJustName} }
    code
    ${TypesStatic.auditor}
    description
    name
    readme
    requirements
    system
    tenant { ${TypesStatic.tenant} }
    __typename
  `

  public static bitmapRouterNode = `
    inlineBitmapper
    managedBitmapper { ${TypesStatic.bitmapperFunction} }
    receiveEdges { ${TypesStatic.edgeShort} }
    receiveMessageType { ${TypesStatic.messageTypeJustName} }
    requirements
    routeTable
    sendEdges { ${TypesStatic.edgeShort} }
    sendMessageType { ${TypesStatic.messageTypeJustName} }
    ${TypesStatic.nodeCommon}
    stopped
  `

  public static processorNode = `
    description
    inlineProcessor
    managedProcessor { ${TypesStatic.processorFunction} }
    receiveEdges { ${TypesStatic.edge} }
    receiveMessageType { ${TypesStatic.messageType} }
    requirements
    sendEdges { ${TypesStatic.edge} }
    sendMessageType { ${TypesStatic.messageType} }
    sequentialProcessing
    ${TypesStatic.nodeCommon}
    stopped
  `

  public static webhookNode = `
    inlineApiAuthenticator
    managedApiAuthenticator { ${TypesStatic.apiauthenticatorFunction} }
    requirements
    sendEdges { ${TypesStatic.edgeShort} }
    sendMessageType { ${TypesStatic.messageTypeJustName} }
    endpoint
    ${TypesStatic.nodeCommon}
    stopped
  `

  public static webSubHubNode = `
    defaultLeaseSeconds
    deliveryRetries
    endpoint
    inlineApiAuthenticator
    managedApiAuthenticator { ${TypesStatic.apiauthenticatorFunction} }
    maxLeaseSeconds
    receiveMessageType { ${TypesStatic.messageType} }
    sendMessageType { ${TypesStatic.messageType} }
    requirements
    sendEdges { ${TypesStatic.edgeShort} }
    signatureAlgorithm
    stopped
    subscriptionSecurity
    ${TypesStatic.nodeCommon}
  `

  public static webSubSubscriptionNode = `
    name  
    callback
    expiration
    secured
    stopped
    subscriber
    topic
  `
  
  public static loadBalancerNode = `
    ${TypesStatic.nodeCommon}
    sendMessageType { ${TypesStatic.messageType} }
    receiveMessageType { ${TypesStatic.messageType} }
    sendEdges { ${TypesStatic.edgeShort} }
    receiveEdges { ${TypesStatic.edgeShort} }
    stopped
  `

  public static alertEmitterNode = `
    sendEdges { ${TypesStatic.edge} }
    sendMessageType { ${TypesStatic.messageType} }
    ${TypesStatic.nodeCommon}
  `

  public static auditEmitterNode = `
    sendEdges { ${TypesStatic.edge} }
    sendMessageType { ${TypesStatic.messageType} }
    ${TypesStatic.nodeCommon}
  `

  public static changeEmitterNode = `
    sendEdges { ${TypesStatic.edge} }
    sendMessageType { ${TypesStatic.messageType} }
    ${TypesStatic.nodeCommon}
  `

  public static logEmitterNode = `
    sendEdges { ${TypesStatic.edge} }
    sendMessageType { ${TypesStatic.messageType} }
    ${TypesStatic.nodeCommon}
  `

  public static timerNode = `
    description
    sendEdges { ${TypesStatic.edge} }
    sendMessageType { ${TypesStatic.messageType} }
    scheduleExpression
    ${TypesStatic.nodeCommon}
    stopped
  `

  public static FilesDotComWebhookNode = `
    description
    sendEdges { ${TypesStatic.edge} }
    sendMessageType { ${TypesStatic.messageType} }
    token
    endpoint
    ${TypesStatic.nodeCommon}
    stopped
  `

  public static deadLetterEmitterNode = `
    sendEdges { ${TypesStatic.edge} }
    sendMessageType { ${TypesStatic.messageType} }
    ${TypesStatic.nodeCommon}
  `

  public static tenantUser = `
    ${TypesStatic.auditor}
    activeAt
    email
    firstName
    graphLayouts {
      layout
      name
    }
    invited { ${TypesStatic.atby} }
    lastName
    role
    status
    tenant { ${TypesStatic.tenant} }
    __typename
  `

  public static apiUser = `
    ${TypesStatic.auditor}
    credentials { ${TypesStatic.cognitoCredentials} }
    description
    role
    username
    tenant { ${TypesStatic.tenant} }
    appsyncEndpoint
  `

  public static crossTenantSendingNode = `
    ${TypesStatic.nodeCommon}
    app {
      name
    }
    inlineProcessor
    managedProcessor { ${TypesStatic.processorFunctionJustNames} }
    receiveEdges { ${TypesStatic.edgeShort} }
    receiveMessageType { ${TypesStatic.messageTypeJustName} }
    sendMessageType { ${TypesStatic.messageTypeJustName} }
    requirements
    sequentialProcessing
    stopped
  `

  public static managedInstance = `
    activationId
    hostname
    instanceId
    ipAddress
    lastPingDateTime
    pingStatus
    registrationDate
  `

  public static mount = `
    description
    source
    target
  `

  public static port = `
    containerPort
    description
    hostAddress
    hostPort
    protocol
  `

  public static managedNode = `
    ${TypesStatic.nodeCommon}
    managedNodeType { name }
    app { name, tableAccess }
    mounts { ${TypesStatic.mount} }
    ports { ${TypesStatic.port} }
    receiveEdges { ${TypesStatic.edgeShort} }
    receiveMessageType { ${TypesStatic.messageTypeJustName} }
    sendEdges { ${TypesStatic.edgeShort} }
    sendMessageType { ${TypesStatic.messageTypeJustName} }
    stopped
  `

  public static managedNodeType = `
    configTemplate
    ${TypesStatic.nodeCommon}
    mountRequirements { ${TypesStatic.mountRequirement} }
    portRequirements { ${TypesStatic.portRequirement} }
    readme
    receiveMessageType { ${TypesStatic.messageType} }
    sendMessageType { ${TypesStatic.messageType} }
    system
    imageUri
  `
  public static crossTenantReceivingNode = `
    app {
      name
    }
    ${TypesStatic.nodeCommon}
    sendEdges { ${TypesStatic.edgeShort} }
    sendMessageType { ${TypesStatic.messageTypeJustName} }
    stopped
  `

  public static externalNode = `
    app {
      ... on ExternalApp {
        name
        tableAccess
      }
      ... on CrossAccountApp { name }
    }
    description
    ${TypesStatic.nodeCommon}
    receiveEdges { ${TypesStatic.edgeShort} }
    receiveMessageType { ${TypesStatic.messageTypeJustName} }
    sendEdges { ${TypesStatic.edgeShort} }
    sendMessageType { ${TypesStatic.messageTypeJustName} }
    stopped
  `

  public static appChangeReceiverNode = `
    app {
      ... on ExternalApp {
        name
        tableAccess
      }
      ... on ManagedApp {
        name
        tableAccess
      }
      ... on CrossAccountApp {
        name
        tableAccess
      }
    }
    ${TypesStatic.nodeCommon}
    receiveMessageType { ${TypesStatic.messageTypeJustName} }
  `

  public static appChangeRouterNode = `
    ${TypesStatic.nodeCommon}
    receiveMessageType { ${TypesStatic.messageTypeJustName} }
    sendEdges { ${TypesStatic.edgeShort} }
    sendMessageType { ${TypesStatic.messageTypeJustName} }
  `

  public static crossAccountApp = `
    account
    ${TypesStatic.nodeCommon}
    tableAccess
    appsyncEndpoint
  `

  public static crossTenantReceivingApp = `
    ${TypesStatic.nodeCommon}
    nodes { ${TypesStatic.crossTenantReceivingNode} }
    sendingApp
    sendingTenant
  `

  public static crossTenantSendingApp = `
    description
    ${TypesStatic.nodeCommon}
    nodes { ${TypesStatic.crossTenantSendingNode} }
    receivingApp
    receivingTenant
  `

  public static externalApp = `
    description
    ${TypesStatic.nodeCommon}
    credentials { ${TypesStatic.cognitoCredentials} }
    appsyncEndpoint
    tableAccess
    nodes { 
      ... on AppChangeReceiverNode { ${TypesStatic.appChangeReceiverNode} }
      ... on ExternalNode { ${TypesStatic.externalNode} }
    }
  `

  public static managedApp = `
    description
    ${TypesStatic.nodeCommon}
    credentials { ${TypesStatic.cognitoCredentials} }
    managedInstances { ${TypesStatic.managedInstance} }
    tableAccess
    nodes { 
      ... on ManagedNode { ${TypesStatic.managedNode} }
      ... on AppChangeReceiverNode { ${TypesStatic.appChangeReceiverNode} }
    }
  `

  public static logEvent = `
    timestamp
    message
    ingestionTime
  `

  public static LoginUser = `
    activeAt
    created { ${TypesStatic.atby} }
    email
    firstName
    lastModified { ${TypesStatic.atby} }
    lastName
    tenantUsers { ${TypesStatic.tenantUser} }
  `

  public static createTenantResponse = `
    checkoutParams
    tenant { ${TypesStatic.tenant} }
  `

  public static usageLineItem = `
    billedUsage
    product
    usageType
    usage
    weight
  `

  public static usageList = `
    month
    year
    total
  `

  public static usage = `
    lineItems { ${TypesStatic.usageLineItem} }
    month
    year
  `
}