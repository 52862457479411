export class Utilities {
  public static toLocaleString(date?: string) : string {
    if (date) {
      return new Date(Date.parse(date)).toLocaleString();
    }

    return '<no date>';
  }
  public static getMonthName(monthNumber: number): string {
    const monthNames = [
        "January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
    ];
    const monthIndex = parseInt(monthNumber.toString(), 10);
    if (isNaN(monthIndex) || monthIndex < 1 || monthIndex > 12) {
        return "";
    }
    return monthNames[monthIndex - 1];
  }
}