import { Button, Card, CardActions, CardContent, CardHeader, Checkbox, Grid, IconButton, TextField, Tooltip, makeStyles } from '@material-ui/core';
import { ITenant } from 'interfaces/tenant';
import React, { useContext, useEffect, useState } from 'react';
import CancelIcon from '@material-ui/icons/Cancel';
import SaveIcon from '@material-ui/icons/Save';
import EditIcon from '@material-ui/icons/Edit';
import { UserRole } from 'enumerations/user-role';
import { MutationsStatic } from 'graphql/mutations-static';
import { TenantModel } from 'models/tenant/tenant-model';
import { GraphQLHelper } from 'utilities/graphql-helper';
import TenantListContext from 'contexts/TenantListContext';
import { SortTenants } from 'models/tenant/tenant-list-model';
import { ConfigView } from 'components/ConfigView';
import { ConfigurableEntity } from 'enumerations/configurable-entity';
import usePaddle from 'utilities/paddle-helper';
import { toast } from 'react-toastify';
import { GetTransactionIdModel } from 'models/tenant/get-transaction-id-model';

interface Props {
  tenant: ITenant,
  currentUserRole: UserRole,
}

const useStyles = makeStyles((theme) => ({
  auditSection: {
    clear: 'both',
    display: 'flex',
    width: '834px',
    justifyContent: 'space-between',
    '@media (max-width: 900px)': {
      width: '100%',
    }
  }
}));

export const TenantConfiguration: React.FC<Props> = (props) => {
  const { paddle, billingEnabled, checkoutClosed, checkoutCompleted } = usePaddle();
  const [editing, setEditing] = useState(false);
  const [description, setDescription] = useState('');
  const [audit, setAudit] = useState<boolean>(false);
  const [tenantHasChanged, setTenantHasChanged] = useState(false);
  const { tenantList, setTenantList } = useContext(TenantListContext);
  const [transactionId, setTransactionId] = useState<string>('');
  const classes = useStyles();  

  useEffect(() => {
    setDescription(props.tenant.description || ' ');
    setAudit(props.tenant.audit || false);
  }, [props.tenant]);

  useEffect(() => {
      if (billingEnabled && props.tenant && props.tenant.name !== 'DEFAULT_TENANT' && props.tenant.subscriptionId && props.tenant.subscriptionId !== '')
          getUpdatePaymentTransactionId(props.tenant)
  }, [billingEnabled, props.tenant])

  const onDescriptionChange = (event: any) => {
    setTenantHasChanged(true);
    setDescription(event.target.value);
  }

  const onAuditChange = () => {
    setTenantHasChanged(true);
    setAudit(!audit);
  };

  const saveTenant = async () => {
    const params = {
      tenant: props.tenant.name,
    }
    const query = MutationsStatic.updateTenant(description, audit);
    const p = await GraphQLHelper.execute<TenantModel>(query, params, TenantModel);
    if (!p.error) {
      // update the tenant list
      const tenant = p.result as TenantModel;
      let tl = tenantList.filter(o => o.name !== tenant.name);
      // add the new update tenant
      tl.push(tenant);
      tl = tl.sort(SortTenants);
      setTenantList(tl);
      setEditing(false);
    }
  }

  useEffect(() => {
    if (checkoutClosed && checkoutCompleted) {
      toast.success("Payment method updated");
      getUpdatePaymentTransactionId(props.tenant);
    }
  }, [checkoutClosed, checkoutCompleted])

  const getUpdatePaymentTransactionId = async(tenant: ITenant) => {
      try {
          const p = await GraphQLHelper.execute<GetTransactionIdModel>(MutationsStatic.getUpdatePaymentTransactionId, { tenant: tenant.name }, GetTransactionIdModel);
          if (!p.error) {
              console.log(p);
              const transactionIdresult = p.result as GetTransactionIdModel;
              setTransactionId(transactionIdresult.transactionId);
          } else {
              console.log('Error getting transactionId', p.errorMessage);
          }
      } catch (err) {
          console.log('Error getting transactionId', err);
      } finally {
      }
  }
  
  const handleChangePaymentMethod = () => {
    paddle?.Checkout.open({ transactionId:transactionId });
  }

  return (
    <div style={{ height: '96%' }}>
      <div style={{ float: 'left', width: '100%', height: '100%' }}>
        <Card style={{ height: '100%', display: 'flex', flexDirection: 'column' }} elevation={0}>
          <CardHeader style={{ flex: 'none', height: '0px', marginTop: '10px' }}
            title={''}
          />
          <div style={{ overflow: 'auto' }}>
            <CardContent style={{ height: 'auto', flex: 'auto' }}>
              {!editing && <div>
                <fieldset style={{ borderRadius: '4px', borderColor: '#888', float: 'left', width: '500px' }}>
                  <legend style={{ fontSize: '12px' }}> Description </legend>
                  {props.tenant.description || '<no description>'}
                </fieldset>
              </div>
              }
              {editing &&
                <TextField
                  error={description.length < 5}
                  helperText={"Description must have a value of atleast 5 characters"}
                  variant="outlined"
                  required
                  autoFocus
                  margin="dense"
                  value={description}
                  id="description"
                  label="Description"
                  style={{ float: 'left' }}
                  onChange={onDescriptionChange}
                />
              }
              <div style={{clear: 'both'}}>
                <ConfigView 
                  tenant={props.tenant} 
                  configurableEntity={ConfigurableEntity.Tenant} 
                  entityKey={props.tenant.name} 
                  parentKey={''}
                  userRole={props.currentUserRole} />
              </div>
              <div className={classes.auditSection}>
                <div style={{width: '30%'}}>
                  Audit
                  <Checkbox
                    disabled={!editing}
                    onClick={onAuditChange}
                    checked={audit}
                  />
                </div>
                {billingEnabled &&
                  <Button
                    disabled={ !transactionId || transactionId === '' }
                    onClick={() => handleChangePaymentMethod()}
                    variant="contained"
                    style={{marginTop: '10px'}}
                    color="primary">
                    Change Payment Method
                  </Button>
                }
              </div>
              {editing &&
                <div style={{ height: '100%' }}>
                  <CardActions disableSpacing style={{ flex: 'end', height: '50px' }}>
                    <Grid container justifyContent="flex-end">
                      <Tooltip title="Save" aria-label="save">
                        <span>
                          <IconButton disabled={!tenantHasChanged || description.length < 5} onClick={() => { saveTenant(); setTenantHasChanged(false); }} aria-label="share">
                            <SaveIcon />
                          </IconButton>
                        </span>
                      </Tooltip>
                      <IconButton onClick={() => { setEditing(false); setTenantHasChanged(false); setAudit(props.tenant.audit || false); }} aria-label="share">
                        <CancelIcon />
                      </IconButton>
                    </Grid>
                  </CardActions>
                </div>
              }
            </CardContent>
            {((props.currentUserRole === UserRole.admin || props.currentUserRole === UserRole.owner) && !editing) &&
              <CardActions disableSpacing style={{ flex: 'end', height: '50px' }}>
                <Grid container justifyContent="flex-end">
                  {tenantHasChanged && <div><Tooltip title="Save" aria-label="edit">
                    <IconButton onClick={() => { setEditing(false); }} aria-label="share">
                      <SaveIcon />
                    </IconButton>
                  </Tooltip>
                    <IconButton onClick={() => { setTenantHasChanged(false); setDescription(props.tenant.description); setAudit(props.tenant.audit || false); setEditing(false); }} aria-label="share">
                      <CancelIcon />
                    </IconButton>
                  </div>
                  }
                  {!tenantHasChanged && <div><Tooltip title="Edit" aria-label="edit">
                    <IconButton onClick={() => { setEditing(true); }} aria-label="share">
                      <EditIcon />
                    </IconButton>
                  </Tooltip>
                  </div>}
                </Grid>
              </CardActions>
            }
          </div>
        </Card>
      </div>
    </div>
  )
}