import { GraphQLData } from "interfaces/graphql-data";
import { ITenant } from "interfaces/tenant";
import { TenantModel } from "./tenant-model";

export class CreateTenantModel implements GraphQLData {
    checkoutParams: string = '';
    tenant: ITenant = new TenantModel();
    lastEvaluatedKey: string = '';

    public static create(checkoutParams: string, tenant: ITenant): CreateTenantModel {
        const t = new CreateTenantModel();
        t.checkoutParams = checkoutParams;
        t.tenant = tenant;
        return t;
    }

    async hydrate(data: any, tenant: ITenant): Promise<void> {
        console.log("create-tenant-model", data.data);

        if (data && data.data && data.data.CreateTenant) {
            const dt = data.data.CreateTenant;
            this.checkoutParams = dt.checkoutParams;
            this.tenant = TenantModel.create(dt.tenant.name, dt.tenant.description, dt.tenant.region, dt.tenant.active, dt.tenant.audit);
        }
    }

}