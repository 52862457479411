import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    footer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: '#333',
    padding: '10px 20px',
    color: 'white',
    position: 'fixed',
    width: '100%',
    bottom: 0,
    left: 0,
    },
    links: {
        paddingRight: '5%'
    },
    link: {
        color: 'white',
        marginLeft: '20px',
        textDecoration: 'none',
        '&:hover': {
            textDecoration: 'underline',
        },
    }
}))
const EchoFooter = () => {
    const classes = useStyles();
    const currentYear = new Date().getFullYear();
    return (
        <div className={classes.footer}>
            <div>
                © {currentYear} EchoStream
            </div>
            <div className={classes.links}>
                <a href="https://docs.echo.stream/v1/docs/legal" target="_blank" className={classes.link}>Legal</a>
                <a href="https://docs.echo.stream/docs/pricing" target="_blank" className={classes.link}>Pricing</a>
                <a href="mailto:support@echo.stream" className={classes.link}>Support</a>
            </div>
        </div>
    )
}

export default EchoFooter;