import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, makeStyles } from "@material-ui/core"
import { CSVLink } from "react-csv";
import DownloadIcon from '@material-ui/icons/CloudDownload';
import { Utilities } from "utilities/utilities";
import { useEffect, useState } from "react";
import { IUsage } from "interfaces/usage";
import { UsageModel } from "models/usage/usage-modal";
import { GraphQLHelper } from "utilities/graphql-helper";
import { QueriesStatic } from "graphql/queries-static";

interface Props {
    show: boolean;
    closed: () => void;
    month: number;
    year: number;
    tenantName: string;
}

const usageLineItemHeaders = [
  { label: "Product", key: "product" },
  { label: "Usage Type", key: "usageType" },
  { label: "Usage", key: "usage" },
  { label: "Weight", key: "weight" },
  { label: "Billed Usage", key: "billedUsage" }
];

const useStyles = makeStyles(() => ({
  buttonProgress: {
    color: '#F5F5F5',
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

const UsageItemList: React.FC<Props> = (props) => {
    const [usage, setUsage] = useState<IUsage>();
    const [isLoading, setIsLoading] = useState(false);
    const classes = useStyles();
    useEffect(() => {
        async function fetchUsage() {
            setIsLoading(true);
            const prop = {
                tenant: props.tenantName,
                month: props.month,
                year: props.year
            }
            const p = await GraphQLHelper.execute<UsageModel>(QueriesStatic.getUsage(), prop, UsageModel);
            console.log(p);
            if (p.error) {
                console.log(p.errorMessage);
            } else {
                const usage = p.result as UsageModel;
                setUsage(usage);
            }
            setIsLoading(false);
        }
        if(props.month !== 0 && props.year !== 0)
            fetchUsage();           
    }, [props.month, props.year]);
    return (
        <Dialog open={props.show} aria-labelledby="form-dialog-title" fullWidth maxWidth="lg">
            <DialogTitle id="form-dialog-title">Usages For {Utilities.getMonthName(props.month)} {props.year}</DialogTitle>
            <DialogContent style={{ paddingTop: 0}}>
                <TableContainer component={Paper} style={{ overflowX: "initial" }}>
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                <TableCell align="left">Product</TableCell>
                                <TableCell align="left">Usage Type</TableCell>
                                <TableCell align="left">Usage</TableCell>
                                <TableCell align="left">Weight</TableCell>
                                <TableCell align="left">Billed Usage</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                isLoading ? <CircularProgress size={24} className={classes.buttonProgress} />
                                :
                                usage && usage.lineItems && usage.lineItems.map((o: any, index: number) => {
                                return (
                                    <TableRow key={`${o.timestamp}-${index}`}>
                                        <TableCell>{o.product}</TableCell>
                                        <TableCell>{o.usageType}</TableCell>
                                        <TableCell>{o.usage}</TableCell>
                                        <TableCell>{o.weight}</TableCell>
                                        <TableCell>{o.billedUsage}</TableCell>
                                    </TableRow>)
                                })
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </DialogContent>
            <DialogActions>
                {
                    usage && usage.lineItems &&
                    <CSVLink data={usage.lineItems} headers={usageLineItemHeaders} filename={`${props.tenantName}_Usages_For_${Utilities.getMonthName(props.month)}_${props.year}.csv`}>
                        <Button variant="contained" color="primary" disabled={isLoading}>
                            <DownloadIcon style={{marginRight: '10px'}} /> Download
                        </Button>
                    </CSVLink>
                }
                <Button variant="contained" onClick={props.closed} color="primary">
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default UsageItemList;