export interface ITenant {
  name: string;
  description: string;
  region: string;
  active: boolean;
  audit?: boolean;
  subscriptionId?: string;
}

export const NoTenant: ITenant = {
  name: 'DEFAULT_TENANT',
  description: 'DEFAULT_TENANT',
  region: 'none',
  active: false,
  audit: false,
  subscriptionId: ''
};
