import { useEffect, useState } from 'react';
import { Environments, initializePaddle, Paddle } from '@paddle/paddle-js';
import axios from 'axios';

const usePaddle = () => {
  const [environment, setEnvironment] = useState<Environments | undefined>();
  const [token, setToken] = useState<string | undefined>();
  const [billingEnabled, setBillingEnabled] = useState<boolean>(false);
  const [checkoutClosed, setCheckoutClosed] = useState<boolean>(false);
  const [checkoutCompleted, setCheckoutCompleted] = useState<boolean>(false);
  const [paddle, setPaddle] = useState<Paddle>();

  useEffect(() => {
    const readCredentials = async () => {
      try {
        const config = await axios.get(`${window.location.protocol}//${window.location.host}/config/config.json`);
        setEnvironment(config.data.paddleEnvironment);
        setToken(config.data.paddleClientSideToken);
        setBillingEnabled(config.data.billingEnabled);
      } catch (error) {
        console.error('Error reading Paddle credentials:', error);
      }
    };

    readCredentials();
  }, []);

  const handleEvents = (data: any) => {
    console.log(data);
    if (data.name === 'checkout.loaded') {
      setCheckoutClosed(false);
      setCheckoutCompleted(false);
    }
    if (data.name === 'checkout.completed')
      setCheckoutCompleted(true);
    if (data.name === 'checkout.closed')
      setCheckoutClosed(true);
  }

  useEffect(() => {
    if (environment && token && billingEnabled) {
      initializePaddle({
        environment: environment, token: token, eventCallback: handleEvents, checkout: {
          settings: {
            theme: 'dark'
          }
        }
      }).then(
        (paddleInstance: Paddle | undefined) => {
          if (paddleInstance) {
            setPaddle(paddleInstance);
          }
        }
      );
    }
  }, [environment, token, billingEnabled]);
  return { paddle, billingEnabled, checkoutClosed, checkoutCompleted };
};

export default usePaddle;
