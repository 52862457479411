import React, { useState } from 'react';
import {
  Button,
  FormControl,
  TextField,
  CircularProgress,
} from '@material-ui/core';
import { Auth } from 'aws-amplify';
import { Formik, Form, Field, ErrorMessage, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { makeStyles } from '@material-ui/core';
import { Alert, Box } from '@mui/material';
import { useHistory } from 'react-router-dom';

interface FormValues {
  currentPassword: string;
  newPassword: string;
  confirmPassword: string;
}

const useStyles = makeStyles(() => ({
  buttonProgress: {
    color: '#F5F5F5',
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  changePasswordPaper: {
    width: '50%',
    marginBottom: '10px',
  },
  changePasswordForm: {
    margin: '10px',
  },
}));

type Severity = 'success' | 'error' | 'info' | 'warning';

interface Response {
  message: string;
  severity: Severity;
}

const ChangePassword: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const [response, setResponse] = useState<Response>({ message: '', severity: 'success' });

  const initialValues: FormValues = {
    currentPassword: '',
    newPassword: '',
    confirmPassword: '',
  };

  const validationSchema = Yup.object({
    currentPassword: Yup.string().required('Current password is required'),
    newPassword: Yup.string().required('New password is required'),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('newPassword')], 'Passwords must match')
      .required('Confirm password is required'),
  });

  const handleSubmit = async (
    values: FormValues,
    { setSubmitting, resetForm }: FormikHelpers<FormValues>
  ) => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      await Auth.changePassword(
        user,
        values.currentPassword,
        values.newPassword
      );
      setResponse({
        message: 'Password is successfully changed!',
        severity: 'success',
      });
      resetForm();
    } catch (error: any) {
      setResponse({
        message: 'Error changing password: ' + error.message,
        severity: 'error',
      });
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Box sx={{ width: '100%', maxWidth: 500, mx: 'auto', mt: 4 }}>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting }) => (
          <Form className={classes.changePasswordForm}>
            <FormControl fullWidth margin='dense'>
              <Field
                as={TextField}
                id='password-current'
                name='currentPassword'
                type='password'
                placeholder='Current Password'
                fullWidth
                variant='outlined'
                margin='dense'
                helperText={<ErrorMessage name='currentPassword' />}
                error={!!ErrorMessage.name}
              />
            </FormControl>
            <FormControl fullWidth margin='dense'>
              <Field
                as={TextField}
                id='password-new'
                name='newPassword'
                type='password'
                placeholder='New Password'
                fullWidth
                variant='outlined'
                margin='dense'
                helperText={<ErrorMessage name='newPassword' />}
                error={!!ErrorMessage.name}
              />
            </FormControl>
            <FormControl fullWidth margin='dense'>
              <Field
                as={TextField}
                id='password-confirm'
                name='confirmPassword'
                type='password'
                placeholder='Confirm Password'
                fullWidth
                variant='outlined'
                margin='dense'
                helperText={<ErrorMessage name='confirmPassword' />}
                error={!!ErrorMessage.name}
              />
            </FormControl>
            <Button
              disabled={isSubmitting}
              variant='contained'
              onClick={() => history.push('/tenants')}
              color='secondary'
            >
              Cancel
            </Button>
            <Button
              type='submit'
              variant='contained'
              color='primary'
              disabled={isSubmitting}
              style={{ marginLeft: 10 }}
            >
              Change Password{' '}
              {isSubmitting && (
                <CircularProgress
                  size={24}
                  color='inherit'
                  className={classes.buttonProgress}
                />
              )}
            </Button>
          </Form>
        )}
      </Formik>
      {response.message && (
        <Box mt={2}>
          <Alert severity={response.severity}>{response.message}</Alert>
        </Box>
      )}
    </Box>
  );
};

export default ChangePassword;
