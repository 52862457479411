import React, { useEffect, useState } from 'react';
import { Auth } from 'aws-amplify';
import { AmplifyTotpSetup } from '@aws-amplify/ui-react';
import { Alert, Box } from '@mui/material';

const MFASettings = () => {
  const [user, setUser] = useState<any>(null);
  const [success, setSuccess] = useState<boolean>(false);

  const fetchUser = async () => {
    const usr = await Auth.currentAuthenticatedUser();
    setUser(usr);
  };

  const handleTOTPSetupSuccess = () => {
    setSuccess(true);
  };

  useEffect(() => {
    fetchUser();
  }, []);

  return (
    <Box sx={{ width: '100%', maxWidth: 500, mx: 'auto', mt: 4 }}>
      {user && (
        <AmplifyTotpSetup
          headerText='Set up your authenticator'
          slot='totp-setup'
          user={user}
          handleComplete={handleTOTPSetupSuccess}
        />
      )}
      {success && (
        <Box mt={2}>
          <Alert severity='success'>TOTP setup successfully!</Alert>
        </Box>
      )}
    </Box>
  );
};

export default MFASettings;