import { Dialog, DialogTitle, Typography, IconButton, DialogContent, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Button } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import { GraphItemType } from './graph/graph-interfaces';
import { QueriesStatic } from 'graphql/queries-static';
import { ITenant } from 'interfaces/tenant';
import { GraphQLHelper } from 'utilities/graphql-helper';
import { ChangeListModel } from 'models/change/change-list-model';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { ChangeModel } from 'models/change/change';
import AtByView from './AtBy';
import ReactDiffViewer from 'react-diff-viewer';

interface Props {
  name: string,
  typeName: string,
  itemType: GraphItemType,
  onCloseDialog: () => void,
  tenant: ITenant,
  open: boolean
}

const ListChangesView: React.FC<Props> = (props) => {

  const [changes, setChanges] = useState<ChangeListModel>(new ChangeListModel());
  const [changeDetailItem, setChangeDetailItem] = useState<ChangeModel>();
  const [showChangeDialog, setShowChangeDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const [nextToken, setNextToken] = useState('{}');

  useEffect( () => {
    const fetchChanges = async () => {
      if (props.name && props.tenant && props.tenant.name !== 'DEFAULT_TENANT') {
        await getChanges(props.tenant, false);
      }
    }
    fetchChanges();
  }, [props.name, props.tenant]);

  const getChanges = async (tnt: ITenant, append: boolean) => {
    setLoading(true);
    const params = {
      tenant: tnt.name,
      name: props.name
    }
    // iso 8601 utc - AWSDateTime format
    // see https://docs.aws.amazon.com/appsync/latest/devguide/scalars.html
    const now = new Date().toISOString()
    let query = '';
    if (props.itemType === GraphItemType.node) {
      query = QueriesStatic.listNodeChanges(now, nextToken, 10, false, '2000-01-01T01:01:01.002Z');
    } else if (props.itemType === GraphItemType.app) {
      query = QueriesStatic.listAppChanges(now, nextToken, 10, false, '2000-01-01T01:01:01.002Z');
    }
    setChanges(new ChangeListModel());
    if (query) {
      const p = await GraphQLHelper.execute<ChangeListModel>(query, params, ChangeListModel);
      if (!p.error) {
        const newChanges = p.result as ChangeListModel;
        console.log("changes", newChanges);
        if (newChanges.lastEvaluatedKey === null) {
          setNextToken("{}")
        } else {
          setNextToken(newChanges.lastEvaluatedKey);
        }
        let allChanges = newChanges.changes;
        if (append) {
          allChanges = changes.changes.concat(newChanges.changes)
        }
        setChanges((prev: any) => ({
          ...prev,
          changes: allChanges
        }));
      }  
    }
    setLoading(false)
  }

  const showChanges = (change: ChangeModel) => {
    setShowChangeDialog(true);
    setChangeDetailItem(change);
  }

  return (
    <>
      <Dialog open={showChangeDialog} aria-labelledby="form-dialog-title" fullWidth maxWidth="lg" >
        <DialogTitle style={{ height: '0px' }}>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <div style={{ marginLeft: 'auto' }}>
              <Typography align="right">
                <IconButton onClick={() => { setShowChangeDialog(false); }}>
                  <CloseIcon />
                </IconButton>
              </Typography>
            </div>
          </div>
        </DialogTitle>
        <DialogContent>
          <div style={{ marginTop: '20px' }}>
            <ReactDiffViewer hideLineNumbers={true} oldValue={changeDetailItem?.oldModel} newValue={changeDetailItem?.newModel} splitView={true} showDiffOnly={false} useDarkTheme={true} leftTitle='Old' rightTitle='New' />
          </div>
        </DialogContent>
      </Dialog>
      <Dialog open={props.open} aria-labelledby="form-dialog-title" fullWidth maxWidth="md" >
        <DialogTitle style={{ height: '0px' }}>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <div>Changes for '{props.name}' - {props.typeName} - {props.itemType}</div>
            <div style={{ marginLeft: 'auto' }}>
              <Typography align="right">
                <IconButton onClick={() => { props.onCloseDialog(); }}>
                  <CloseIcon />
                </IconButton>
              </Typography>
            </div>
          </div>

        </DialogTitle>
        <DialogContent>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align="left">When</TableCell>
                  <TableCell align="left">Type</TableCell>
                  <TableCell align="left">Old</TableCell>
                  <TableCell align="left">New</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {changes && changes.changes && changes.changes.map((o, index) => {
                  return (
                    <TableRow key={`${o.typeName}-${index}`}>
                      <TableCell style={{ width: '250px' }}><AtByView label='' atby={o.newModelAuditor || o.oldModelAuditor} /></TableCell>
                      <TableCell style={{ width: '50px' }}>{o.typeName}</TableCell>
                      <TableCell style={{ width: '200px' }}>{o.oldModel && o.oldModel.substr(0, 50)}{o.oldModel && o.oldModel.length > 50 ? '...': ''}</TableCell>
                      <TableCell style={{ width: '200px' }}>{o.newModel && o.newModel.substr(0, 50)}{o.newModel && o.newModel.length > 50 ? '...': ''}...</TableCell>
                      <TableCell>
                        <IconButton size="small" onClick={() => showChanges(o)}>
                          <MoreHorizIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>)
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <br />
          <Button 
            disabled={loading || nextToken === "{}"} 
            onClick={() => getChanges(props.tenant, true)}
            variant="contained" 
            color="primary">
              Load More
          </Button>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default ListChangesView;