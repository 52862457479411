import { Button, CircularProgress, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, makeStyles } from "@material-ui/core";
import { UserRole } from "enumerations/user-role";
import { QueriesStatic } from "graphql/queries-static";
import { ITenant } from "interfaces/tenant";
import { UsageListModel } from "models/usage/usage-list-modal";
import { useEffect, useState } from "react";
import { GraphQLHelper } from "utilities/graphql-helper";
import { CSVLink } from "react-csv";
import DownloadIcon from '@material-ui/icons/CloudDownload';
import UsageItemList from "./UsageItemList";
import { IUsage } from "interfaces/usage";
import { Utilities } from "utilities/utilities";

interface Props {
  tenant: ITenant,
  currentUserRole: UserRole | undefined
}

const usageHeaders = [
  { label: "Month", key: "month" },
  { label: "Year", key: "year" },
  { label: "Total", key: "total" }
];

const useStyles = makeStyles(() => ({
  buttonProgress: {
    color: '#F5F5F5',
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));
const Usages: React.FC<Props> = (props) => {
    const [isLoading, setIsLoading] = useState(false);
    const [usageList, setUsageList] = useState<Array<IUsage>>([]);
    const [usageListWithMonthName, setUsageListWithMonthName] = useState<Array<IUsage>>([]);
    const [open, setOpen] = useState<boolean>(false);
    const [selected, setSelected] = useState<IUsage>();
    const classes = useStyles();
    useEffect(() => {
        async function fetchUsages() {
            setIsLoading(true);
            const p = await GraphQLHelper.execute<UsageListModel>(QueriesStatic.listUsages(), { tenant: props.tenant.name }, UsageListModel);
            console.log(p);
            if (p.error) {
                console.log(p.errorMessage);
            } else {
                const usageList = p.result as UsageListModel;
                console.log(usageList);
                setUsageList(usageList.usages);
                const usgWthMonth = usageList.usages.map((item: any) => ({
                    ...item,
                    month: Utilities.getMonthName(item.month) // Adjust for zero-based array index
                }));
                setUsageListWithMonthName(usgWthMonth);
            }
            setIsLoading(false);
        } 
        fetchUsages();           
    }, []);

    const showItemListDialog = (item: IUsage) => {
        setSelected(item);
        setOpen(true);
    }

    const closeShowItemListDialog = () => {
        setOpen(false);
    }

    return (
        <div style={{margin: '1%'}}>
            <div style={{display:"flex", justifyContent:"space-between"}}>
                <h2>Usage Summary</h2>
                <div style={{ marginLeft: '10px', marginTop: '20px'}}>
                    <CSVLink data={usageListWithMonthName} headers={usageHeaders} filename={`${props.tenant.name}_Usage_Summary.csv`}>
                        <Button variant="contained" color="primary">
                            <DownloadIcon style={{marginRight: '10px'}} /> Download
                        </Button>
                    </CSVLink>
                </div>
            </div>
            <div>
                <TableContainer component={Paper} style={{ overflowX: "initial" }}>
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                <TableCell align="left">Year</TableCell>
                                <TableCell align="left">Month</TableCell>
                                <TableCell align="left">Total</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                isLoading ? <CircularProgress size={24} className={classes.buttonProgress}/>
                                :
                                usageList && usageList.map((o: any, index: number) => {
                                return (
                                    <TableRow key={`${o.timestamp}-${index}`} style={{cursor:"pointer"}} onClick={(e) => showItemListDialog(o)}>
                                        <TableCell style={{ width: '200px' }}>{o.year}</TableCell>
                                        <TableCell style={{ width: '250px' }}>{Utilities.getMonthName(o.month)}</TableCell>
                                        <TableCell style={{ width: '200px' }}>{o.total}</TableCell>
                                    </TableRow>)
                                })
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
            <UsageItemList
                show={open}
                closed={closeShowItemListDialog}
                month={selected?.month || 0}
                year={selected?.year || 0}
                tenantName={props.tenant?.name || ''}
            />
        </div>
    )
}
export default Usages;
