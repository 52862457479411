import { ApiUserRole } from "enumerations/api-user-role";
import { IApiUser } from "interfaces/api-user";
import { IAtBy } from "interfaces/at-by";
import { ICredentials } from "interfaces/credentials";
import { GraphQLData } from "interfaces/graphql-data";
import { ITenant } from "interfaces/tenant";
import { CredentialsModel } from "models/credentials-model";
import { AtByModel } from "../at-by-model";
import { TenantModel } from "../tenant/tenant-model";

export class ApiUserModel implements IApiUser, GraphQLData {
  lastEvaluatedKey: string = '';
  credentials: ICredentials = CredentialsModel.create('', '', '', '');
  created: IAtBy = new AtByModel('', '');
  lastModified: IAtBy = new AtByModel('', '');
  username: string = '';
  description: string = '';
  role: ApiUserRole = ApiUserRole.read_only;
  tenant: ITenant = TenantModel.create('', '', '', false, false);
  appsyncEndpoint: string = '';

  public static create(
    credentials: ICredentials,
    created: AtByModel, 
    lastModified: AtByModel,
    username: string,
    description: string,
    role: ApiUserRole,
    tenant: TenantModel,
    appsyncEndpoint: string) : ApiUserModel {
    const t = new ApiUserModel();
    t.credentials = credentials;
    t.created = created;
    t.username = username;
    t.lastModified = lastModified;
    t.description = description
    t.role = role;
    t.tenant = tenant;
    t.appsyncEndpoint = appsyncEndpoint;
    return t;
  }

  async hydrate(data: any, tenant: ITenant): Promise<void> {
    console.log("api-user-model", data.data);

    if (data && data.data && data.data.CreateApiUser) {
      const dt = data.data.CreateApiUser;      
      this.credentials = CredentialsModel.create(dt.credentials.clientId, dt.credentials.password, dt.credentials.username, dt.credentials.userPoolId);
      this.created = new AtByModel(dt.created.at, dt.created.by);
      this.description = dt.description;
      this.lastModified = new AtByModel(dt.lastModified.at, dt.lastModified.by);
      this.username = dt.username;
      this.role = dt.role;
      this.tenant = TenantModel.create(dt.tenant.name, dt.tenant.description, dt.tenant.region, dt.tenant.active, dt.tenant.audit);
      this.appsyncEndpoint = dt.appsyncEndpoint;
    }
  }
}