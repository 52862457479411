import { ConfigurableEntity } from "enumerations/configurable-entity";
import { CredentialEntity } from "enumerations/credential-entity";
import { TypesStatic } from "./types-static";

export class QueriesStatic {
  public static listTenants = /* GraphQL */ `
    query ListTenants($exclusiveStartKey: AWSJSON, $limit: Int) {
      ListTenants(exclusiveStartKey: $exclusiveStartKey, limit: $limit) {
        echos {
          ${TypesStatic.tenant}
        }
        ${TypesStatic.paginator}
      }
    }
  `;

  public static listTenantUsers = /* GraphQL */ `
    query ListTenantUsers($tenant: String!, $exclusiveStartKey: AWSJSON, $limit: Int) {
      ListTenantUsers(tenant: $tenant, exclusiveStartKey: $exclusiveStartKey, limit: $limit) {
        echos { ${TypesStatic.tenantUser} }
        ${TypesStatic.paginator}
      }
    }
  `;

  public static getSupportedRegions = /* GraphQL */ `
    query GetSupportedRegions {
      GetSupportedRegions
    }
  `;


  public static getTenantUser = /* GraphQL */ `
    query GetTenantUser($email: AWSEmail!, $tenant: String!) {
      GetTenantUser(email: $email, tenant: $tenant) {
        ${TypesStatic.tenantUser}
      }
    }
  `;

  public static getTenantUserRole = /* GraphQL */ `
    query GetTenantUser($email: AWSEmail!, $tenant: String!) {
      GetTenantUser(email: $email, tenant: $tenant) {
        role
      }
    }
  `;

  public static getTenantUserGraphLayouts = /* GraphQL */ `
    query GetTenantUser($email: AWSEmail!, $tenant: String!) {
      GetTenantUser(email: $email, tenant: $tenant) {
        graphLayouts {
          layout
          name
        }
      }
    }
  `;

  public static listMessageTypes = /* GraphQL */ `
    query ListMessageTypes($exclusiveStartKey: AWSJSON, $limit: Int, $tenant: String!) {
      ListMessageTypes(exclusiveStartKey: $exclusiveStartKey, limit: $limit, tenant: $tenant) {
        echos { ${TypesStatic.messageType} }
        ${TypesStatic.paginator}
      }
    }
  `;

  public static listFunctions = /* GraphQL */ `
    query ListFunctions($exclusiveStartKey: AWSJSON, $limit: Int, $tenant: String!) {
      ListFunctions(exclusiveStartKey: $exclusiveStartKey, limit: $limit, tenant: $tenant) {
        echos {
          ... on BitmapperFunction { ${TypesStatic.bitmapperFunction} }
          ... on ProcessorFunction { ${TypesStatic.processorFunction} }
          ... on ApiAuthenticatorFunction { ${TypesStatic.apiauthenticatorFunction} }
        }
        ${TypesStatic.paginator}
      }
    }
  `;

  public static listApps = /* GraphQL */ `
    query ListApps($tenant: String!, $exclusiveStartKey: AWSJSON, $limit: Int, $types: [String]) {
      ListApps(tenant: $tenant, exclusiveStartKey: $exclusiveStartKey, limit: $limit, types: $types) {
        echos {
          ${TypesStatic.auditor}
          description
          name
          tenant { ${TypesStatic.tenant} }
          __typename
          ... on ExternalApp {
            tableAccess
            appsyncEndpoint
          }
          ... on ManagedApp {
            tableAccess
            managedInstances { ${TypesStatic.managedInstance}}
          }
          ... on CrossAccountApp {
            tableAccess
            account
            appsyncEndpoint
          }
          ... on CrossTenantSendingApp {
            receivingApp
            receivingTenant  
          }
          ... on CrossTenantReceivingApp {
            sendingApp
            sendingTenant
          }
        }
        ${TypesStatic.paginator}
      }
    }
  `;

  public static listNodes = /* GraphQL */ `
    query ListNodes($tenant: String!, $exclusiveStartKey: AWSJSON, $limit: Int, $types: [String]) {
      ListNodes(tenant: $tenant, exclusiveStartKey: $exclusiveStartKey, limit: $limit, types: $types) {
        echos {
          ... on AlertEmitterNode { ${TypesStatic.alertEmitterNode} }
          ... on ChangeEmitterNode { ${TypesStatic.changeEmitterNode} }
          ... on LogEmitterNode { ${TypesStatic.logEmitterNode} }
          ... on LogEmitterNode { ${TypesStatic.logEmitterNode} }
          ... on DeadLetterEmitterNode { ${TypesStatic.deadLetterEmitterNode} }
          ... on ProcessorNode { ${TypesStatic.processorNode} }
          ... on TimerNode { ${TypesStatic.timerNode} }
          ... on FilesDotComWebhookNode { ${TypesStatic.FilesDotComWebhookNode} }
          ... on WebhookNode { ${TypesStatic.webhookNode} }
          ... on LoadBalancerNode { ${TypesStatic.loadBalancerNode} }
          ... on BitmapRouterNode { ${TypesStatic.bitmapRouterNode} }
          ... on ManagedNode { ${TypesStatic.managedNode} }
          ... on CrossTenantSendingNode { ${TypesStatic.crossTenantSendingNode} }
          ... on CrossTenantReceivingNode { ${TypesStatic.crossTenantReceivingNode} }
          ... on ExternalNode { ${TypesStatic.externalNode} }
          ... on AppChangeReceiverNode { ${TypesStatic.appChangeReceiverNode} }
          ... on AppChangeRouterNode { ${TypesStatic.appChangeRouterNode} }
          ... on AuditEmitterNode { ${TypesStatic.auditEmitterNode} }
          ... on WebSubHubNode { ${TypesStatic.webSubHubNode} }
        }
        ${TypesStatic.paginator}
      }
    }
  `;

  public static listApiUsers = /* GraphQL */ `
    query ListApiUsers($tenant: String!, $exclusiveStartKey: AWSJSON, $limit: Int) {
      ListApiUsers(tenant: $tenant, exclusiveStartKey: $exclusiveStartKey, limit: $limit) {
        echos {
          credentials { ${TypesStatic.cognitoCredentials} }
          ${TypesStatic.auditor}
          description
          username
          tenant { ${TypesStatic.tenant} }
          role
          __typename
          appsyncEndpoint
        }
        ${TypesStatic.paginator}
      }
    }
  `;

  public static listManagedNodeType = /* GraphQL */ `
    query ListManagedNodeTypes($exclusiveStartKey: AWSJSON, $limit: Int, $tenant: String!) {
      ListManagedNodeTypes(exclusiveStartKey: $exclusiveStartKey, limit: $limit, tenant: $tenant) {
        echos {
          ${TypesStatic.managedNodeType}
        }
        ${TypesStatic.paginator}
      }
    }
  `;

  public static listNodeChanges(endDateTime: string, exclusiveStartKey: string, limit: number, sortAscending: boolean, startDateTime: string) {
    return /* GraphQL */ `
      query GetNode($name: String!, $tenant: String!) {
        GetNode(name: $name, tenant: $tenant) {
          ... on BitmapRouterNode {
            ListChanges(endDateTime: ${JSON.stringify(endDateTime)}, exclusiveStartKey: ${JSON.stringify(exclusiveStartKey)}, limit: ${limit}, sortAscending: ${sortAscending}, startDateTime: ${JSON.stringify(startDateTime)}) {
              echos {
                new
                old
                lastModified { ${TypesStatic.atby} }
              }
              ${TypesStatic.paginator}
            }
          }
          ... on ProcessorNode {
            ListChanges(endDateTime: ${JSON.stringify(endDateTime)}, exclusiveStartKey: ${JSON.stringify(exclusiveStartKey)}, limit: ${limit}, sortAscending: ${sortAscending}, startDateTime: ${JSON.stringify(startDateTime)}) {
              echos {
                new
                old
                lastModified { ${TypesStatic.atby} }
              }
              ${TypesStatic.paginator}
            }
          }
          ... on TimerNode {
            ListChanges(endDateTime: ${JSON.stringify(endDateTime)}, exclusiveStartKey: ${JSON.stringify(exclusiveStartKey)}, limit: ${limit}, sortAscending: ${sortAscending}, startDateTime: ${JSON.stringify(startDateTime)}) {
              echos {
                new
                old
                lastModified { ${TypesStatic.atby} }
              }
              ${TypesStatic.paginator}
            }
          }
          ... on CrossTenantSendingNode {
            ListChanges(endDateTime: ${JSON.stringify(endDateTime)}, exclusiveStartKey: ${JSON.stringify(exclusiveStartKey)}, limit: ${limit}, sortAscending: ${sortAscending}, startDateTime: ${JSON.stringify(startDateTime)}) {
              echos {
                new
                old
                lastModified { ${TypesStatic.atby} }
              }
              ${TypesStatic.paginator}
            }
          }
          ... on CrossTenantReceivingNode {
            ListChanges(endDateTime: ${JSON.stringify(endDateTime)}, exclusiveStartKey: ${JSON.stringify(exclusiveStartKey)}, limit: ${limit}, sortAscending: ${sortAscending}, startDateTime: ${JSON.stringify(startDateTime)}) {
              echos {
                new
                old
                lastModified { ${TypesStatic.atby} }
              }
              ${TypesStatic.paginator}
            }
          }
          ... on FilesDotComWebhookNode {
            ListChanges(endDateTime: ${JSON.stringify(endDateTime)}, exclusiveStartKey: ${JSON.stringify(exclusiveStartKey)}, limit: ${limit}, sortAscending: ${sortAscending}, startDateTime: ${JSON.stringify(startDateTime)}) {
              echos {
                new
                old
                lastModified { ${TypesStatic.atby} }
              }
              ${TypesStatic.paginator}
            }
          }
          ... on WebhookNode {
            ListChanges(endDateTime: ${JSON.stringify(endDateTime)}, exclusiveStartKey: ${JSON.stringify(exclusiveStartKey)}, limit: ${limit}, sortAscending: ${sortAscending}, startDateTime: ${JSON.stringify(startDateTime)}) {
              echos {
                new
                old
                lastModified { ${TypesStatic.atby} }
              }
              ${TypesStatic.paginator}
            }
          }
          ... on LoadBalancerNode {
            ListChanges(endDateTime: ${JSON.stringify(endDateTime)}, exclusiveStartKey: ${JSON.stringify(exclusiveStartKey)}, limit: ${limit}, sortAscending: ${sortAscending}, startDateTime: ${JSON.stringify(startDateTime)}) {
              echos {
                new
                old
                lastModified { ${TypesStatic.atby} }
              }
              ${TypesStatic.paginator}
            }
          }
          ... on WebSubHubNode {
            ListChanges(endDateTime: ${JSON.stringify(endDateTime)}, exclusiveStartKey: ${JSON.stringify(exclusiveStartKey)}, limit: ${limit}, sortAscending: ${sortAscending}, startDateTime: ${JSON.stringify(startDateTime)}) {
              echos {
                new
                old
                lastModified { ${TypesStatic.atby} }
              }
              ${TypesStatic.paginator}
            }
          }
          ... on ExternalNode {
            ListChanges(endDateTime: ${JSON.stringify(endDateTime)}, exclusiveStartKey: ${JSON.stringify(exclusiveStartKey)}, limit: ${limit}, sortAscending: ${sortAscending}, startDateTime: ${JSON.stringify(startDateTime)}) {
              echos {
                new
                old
                lastModified { ${TypesStatic.atby} }
              }
              ${TypesStatic.paginator}
            }
          }
          ... on ManagedNode {
            ListChanges(endDateTime: ${JSON.stringify(endDateTime)}, exclusiveStartKey: ${JSON.stringify(exclusiveStartKey)}, limit: ${limit}, sortAscending: ${sortAscending}, startDateTime: ${JSON.stringify(startDateTime)}) {
              echos {
                new
                old
                lastModified { ${TypesStatic.atby} }
              }
              ${TypesStatic.paginator}
            }
          }
          ... on WebSubSubscriptionNode {
            ListChanges(endDateTime: ${JSON.stringify(endDateTime)}, exclusiveStartKey: ${JSON.stringify(exclusiveStartKey)}, limit: ${limit}, sortAscending: ${sortAscending}, startDateTime: ${JSON.stringify(startDateTime)}) {
              echos {
                new
                old
                lastModified { ${TypesStatic.atby} }
              }
              ${TypesStatic.paginator}
            }
          }
        }
      }
    `
  };

  public static listAppChanges(endDateTime: string, exclusiveStartKey: string, limit: number, sortAscending: boolean, startDateTime: string) {
    return /* GraphQL */ `
      query GetApp($name: String!, $tenant: String!) {
        GetNode: GetApp(name: $name, tenant: $tenant) {
          ... on CrossAccountApp {
            ListChanges(endDateTime: ${JSON.stringify(endDateTime)}, exclusiveStartKey: ${JSON.stringify(exclusiveStartKey)}, limit: ${limit}, sortAscending: ${sortAscending}, startDateTime: ${JSON.stringify(startDateTime)}) {
              echos {
                new
                old
                lastModified { ${TypesStatic.atby} }
              }
              ${TypesStatic.paginator}
            }
          }
          ... on CrossTenantReceivingApp {
            ListChanges(endDateTime: ${JSON.stringify(endDateTime)}, exclusiveStartKey: ${JSON.stringify(exclusiveStartKey)}, limit: ${limit}, sortAscending: ${sortAscending}, startDateTime: ${JSON.stringify(startDateTime)}) {
              echos {
                new
                old
                lastModified { ${TypesStatic.atby} }
              }
              ${TypesStatic.paginator}
            }
          }
          ... on CrossTenantSendingApp {
            ListChanges(endDateTime: ${JSON.stringify(endDateTime)}, exclusiveStartKey: ${JSON.stringify(exclusiveStartKey)}, limit: ${limit}, sortAscending: ${sortAscending}, startDateTime: ${JSON.stringify(startDateTime)}) {
              echos {
                new
                old
                lastModified { ${TypesStatic.atby} }
              }
              ${TypesStatic.paginator}
            }
          }
          ... on ExternalApp {
            ListChanges(endDateTime: ${JSON.stringify(endDateTime)}, exclusiveStartKey: ${JSON.stringify(exclusiveStartKey)}, limit: ${limit}, sortAscending: ${sortAscending}, startDateTime: ${JSON.stringify(startDateTime)}) {
              echos {
                new
                old
                lastModified { ${TypesStatic.atby} }
              }
              ${TypesStatic.paginator}
            }
          }
          ... on ManagedApp {
            ListChanges(endDateTime: ${JSON.stringify(endDateTime)}, exclusiveStartKey: ${JSON.stringify(exclusiveStartKey)}, limit: ${limit}, sortAscending: ${sortAscending}, startDateTime: ${JSON.stringify(startDateTime)}) {
              echos {
                new
                old
                lastModified { ${TypesStatic.atby} }
              }
              ${TypesStatic.paginator}
            }
          }
        }
      }
    `
  };

  public static listLogEvents(endDateTime: string, nextToken: string, limit: number, filterPattern: string, startDateTime: string) {
    return /* GraphQL */ `
      query GetNode($name: String!, $tenant: String!) {
        GetNode(name: $name, tenant: $tenant) {
          ... on BitmapRouterNode {
            ListLogEvents(endDateTime: ${JSON.stringify(endDateTime)}, nextToken: "${nextToken}", limit: ${limit}, filterPattern: "${filterPattern}", startDateTime: ${JSON.stringify(startDateTime)}) {
              events { ${TypesStatic.logEvent} }
              nextToken
            }
          }
          ... on ProcessorNode {
            ListLogEvents(endDateTime: ${JSON.stringify(endDateTime)}, nextToken: "${nextToken}", limit: ${limit}, filterPattern: "${filterPattern}", startDateTime: ${JSON.stringify(startDateTime)}) {
              events { ${TypesStatic.logEvent} }
              nextToken
            }
          }
          ... on ManagedNode {
            ListLogEvents(endDateTime: ${JSON.stringify(endDateTime)}, nextToken: "${nextToken}", limit: ${limit}, filterPattern: "${filterPattern}", startDateTime: ${JSON.stringify(startDateTime)}) {
              events { ${TypesStatic.logEvent} }
              nextToken
            }
          }
          ... on CrossTenantSendingNode {
            ListLogEvents(endDateTime: ${JSON.stringify(endDateTime)}, nextToken: "${nextToken}", limit: ${limit}, filterPattern: "${filterPattern}", startDateTime: ${JSON.stringify(startDateTime)}) {
              events { ${TypesStatic.logEvent} }
              nextToken
            }
          }
          ... on WebhookNode {
            ListLogEvents(endDateTime: ${JSON.stringify(endDateTime)}, nextToken: "${nextToken}", limit: ${limit}, filterPattern: "${filterPattern}", startDateTime: ${JSON.stringify(startDateTime)}) {
              events { ${TypesStatic.logEvent} }
              nextToken
            }
          }
          ... on WebSubHubNode {
            ListLogEvents(endDateTime: ${JSON.stringify(endDateTime)}, nextToken: "${nextToken}", limit: ${limit}, filterPattern: "${filterPattern}", startDateTime: ${JSON.stringify(startDateTime)}) {
              events { ${TypesStatic.logEvent} }
              nextToken
            }
          }
        }
      }
    `
  };

  public static getConfig(entity: ConfigurableEntity) {
    if (entity === ConfigurableEntity.Tenant) {
      return /* GraphQL */ `
        query Get${entity}($name: String!) {
          GetConfig: Get${entity}(tenant: $name) {
            config
          }
        }
      `
    } else if (entity === ConfigurableEntity.ExternalApp || entity === ConfigurableEntity.CrossAccountApp || entity === ConfigurableEntity.ManagedApp) {
      return /* GraphQL */ `
        query GetApp($name: String!, $tenant: String!) {
          GetConfig: GetApp(name: $name, tenant: $tenant) {
            ... on ExternalApp {
              config
            }
            ... on CrossAccountApp {
              config
            }
            ... on ManagedApp {
              config
            }
          }
        }
      `
    } else if (entity === ConfigurableEntity.BitmapRouterNode || entity === ConfigurableEntity.ProcessorNode || entity === ConfigurableEntity.ExternalNode || entity === ConfigurableEntity.ManagedNode || entity === ConfigurableEntity.WebhookNode) {
      return /* GraphQL */ `
        query GetNode($name: String!, $tenant: String!) {
          GetConfig: GetNode(name: $name, tenant: $tenant) {
            ... on BitmapRouterNode {
              config
            }
            ... on ProcessorNode {
              config
            }
            ... on ExternalNode {
              config
            }
            ... on ManagedNode {
              config
            }
            ... on WebhookNode {
              config
            }
          }
        }
      `
    }
    return `
    `
  };

  //may be able to get rid of app part of query since endpoint is stored in an attribute now
  public static getCredentials(entity: CredentialEntity) {
    if (entity === CredentialEntity.ApiUser) {
      return /* GraphQL */ `
        query GetApiUser($name: String!, $tenant: String!) {
          GetCredentials: GetApiUser(username: $name, tenant: $tenant) {
            credentials { ${TypesStatic.cognitoCredentials} }
          }
        }
      `
    } else {
      return /* GraphQL */ `
        query GetApp($name: String!, $tenant: String!) {
          GetCredentials: GetApp(name: $name, tenant: $tenant) {
            ... on ManagedApp {
              credentials { ${TypesStatic.cognitoCredentials} }
            }
            ... on CrossAccountApp {
              credentials { ${TypesStatic.cognitoCredentials} }
            }
            ... on ExternalApp {
              credentials { ${TypesStatic.cognitoCredentials} }
            }
          }
        }
      `
    }
  };

  public static getManagedApp = /* GraphQL */ `
    query GetApp($name: String!, $tenant: String!) {
      GetApp(name: $name, tenant: $tenant) {
        ... on ManagedApp {
          iso
          managedInstances { ${TypesStatic.managedInstance} }
        }
      }
    }
  `;
  
  public static getManagedAppUserdata = /* GraphQL */ `
    query GetApp($name: String!, $tenant: String!) {
      GetApp(name: $name, tenant: $tenant) {
        ... on ManagedApp {
          userdata
        }
      }
    }
  `;

  public static getIAMPolicy = /* GraphQL */ `
    query GetApp($name: String!, $tenant: String!) {
      GetApp(name: $name, tenant: $tenant) {
        ... on CrossAccountApp {
          iamPolicy
        }
      }
    }
  `;

  public static getUser = /* GraphQL */ `
    query GetUser{
      GetUser {
        ${TypesStatic.LoginUser}
      }
    }
  `;

  public static getUserEmail = /* GraphQL */ `
    query GetUser{
      GetUser {
        email
      }
    }
  `;

  public static listSubscriptions(callback: string, subscriber: string, topic: string, exclusiveStartKey: string) {
    return /* GraphQL */ `
    query GetNode($name: String!, $tenant: String!) {
      GetNode(name: $name, tenant: $tenant) {
        ...on WebSubHubNode { 
          ListSubscriptions(
            callback: ${JSON.stringify(callback ? callback : null)}, 
            subscriber: ${JSON.stringify(subscriber)}, 
            topic: ${JSON.stringify(topic ? topic : null)}, 
            exclusiveStartKey: ${JSON.stringify(exclusiveStartKey ? exclusiveStartKey : null)},
            limit: 100) {
            echos{
              ${ TypesStatic.webSubSubscriptionNode }
            }
            ${TypesStatic.paginator}
          } 
        }
      }    
    } 
  `
  }

  public static listUsages() {
    return /* GraphQL */ `
    query GetTenant($tenant: String!) {
      GetTenant(tenant: $tenant) {
        usages {
          ${TypesStatic.usageList}
        }
      }
    }
  `
  }

  public static getUsage() {
    return /* GraphQL */ `
    query GetTenant($tenant: String!, $month: Int!, $year: Int!) {
      GetTenant(tenant: $tenant) {
        GetUsage(month: $month, year: $year) {
          ${TypesStatic.usage}
        }
      }
    }
  `
  }

}