import { IUsage } from "interfaces/usage";
import { IUsageLineItem } from "interfaces/usage-line-item";
import { GraphQLData } from "interfaces/graphql-data";
import { ITenant } from "interfaces/tenant";

export class UsageModel implements IUsage, GraphQLData {
  lineItems: Array<IUsageLineItem> = [];
  month: number = 0;
  total: number = 0;
  year: number = 0;
  lastEvaluatedKey: string = '';

  public static create(lineItems: Array<IUsageLineItem>, month: number, total: number, year: number) : UsageModel {
    const u = new UsageModel();
    u.lineItems = lineItems;
    u.month = month;
    u.total = total;
    u.year = year;
    return u;
  }

  async hydrate(data: any, tenant: ITenant): Promise<void> {
    //..
    // data.data.GetTenant.GetUpdatePaymentTransactionId (string)
    console.log(data);
    if (data && data.data && data.data.GetTenant && data.data.GetTenant.GetUsage) {
      console.log(data.data.GetTenant.GetUsage);
      const usg = data.data.GetTenant.GetUsage;
      this.lineItems = sortLineItems(usg.lineItems);
      this.month = usg.month;
      this.total = usg.total;
      this.year = usg.year;
    } else {
      throw new Error('data structured passed in does not contain data, data.data, data.data.GetTenant or data.data.GetTenant.GetUsage');
    }
  }
}

export const sortLineItems = (items: Array<IUsageLineItem>) => {
  return items.sort((a, b) => {
    if (a.product < b.product) return -1;
    if (a.product > b.product) return 1;
    if (a.usageType < b.usageType) return -1;
    if (a.usageType > b.usageType) return 1;
    return 0;
  });
}