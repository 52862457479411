import React, { useContext, useEffect, useState } from "react";
import { Button, makeStyles, withStyles } from "@material-ui/core";
import { Box } from '@mui/material';
import { useLocation, useHistory } from "react-router-dom";
import TenantUsers from "components/tenantUsers/TenantUsers";
import TenantContext from "contexts/TenantContext";
import TenantListContext from "contexts/TenantListContext";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import TenantMessageTypes from "components/messageTypes/TenantMessageTypes";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import TenantFunctions from "components/functionTypes/TenantFunctions";
import TenantAPIUsers from "components/apiUsers/TenantAPIUsers";
import { ITenant } from "interfaces/tenant";
import { UserRole } from "enumerations/user-role";
import ManagedNodeTypesView from "components/managedNodeTypes/ManagedNodeTypesView";
import { TenantConfiguration } from "./TenantConfiguration";
import { GraphQLHelper } from "utilities/graphql-helper";
import { QueriesStatic } from "graphql/queries-static";
import { LoginUserModel } from "models/login-user-model";
import { TenantUserModel } from "models/tenant-user/tenant-user-model";
import Usages from "components/Usages";

const CustomTabs = withStyles({
  indicator: {
    backgroundColor: "#0EBCB7",
  },
})(Tabs);

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      style={{height: '100%'}}
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <div style={{height: '100%'}}>
          {children}
      </div>
    )}
    </div>
  );
}

function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    color: "#fff",
  },
  paper: {
    padding: theme.spacing(2),
    marginLeft: "5px",
    marginRight: "5px",
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  button: {
    margin: theme.spacing(1),
  },
}));

const TenantSettings = (props: any) => {
  const { tenant, setTenant } = useContext(TenantContext);
  const { tenantList } = useContext(TenantListContext);
  const [value, setValue] = useState(0);
  const [currentUserRole, setCurrentUserRole] = useState<UserRole>(UserRole.read_only);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  const classes = useStyles();
  const history = useHistory();
  let { search } = useLocation();

  useEffect(() => {
    const query = new URLSearchParams(search);
    const pew = query.get("t")?.replaceAll("'", "");
    const foundTenant = tenantList.find((o) => o.name === pew);
    if (foundTenant) {
      console.log(`Found tenant: ${foundTenant.name}`);
      setTenant(foundTenant);
      getCurrentUser(foundTenant);
    } else {
      console.log(`Couldn't find tenant: ${pew}`);
    }
  }, [search, setTenant, tenantList]);

  const onBack = () => {
    history.push("/tenants");
  };

  const getCurrentUser = async (tnt: ITenant) => {
    try {
      const params = {}
      const p = await GraphQLHelper.execute<LoginUserModel>(QueriesStatic.getUserEmail, params, LoginUserModel);
      if (!p.error) {
        const lu = p.result as LoginUserModel;
        const params1 = {
          email: lu.email,
          tenant: tnt.name,
        };
        const p1 = await GraphQLHelper.execute<TenantUserModel>(QueriesStatic.getTenantUserRole, params1, TenantUserModel);
        if (!p1.error){
          const tu = p1.result as TenantUserModel;
          setCurrentUserRole(tu.role);
        }
      }
    } catch (error) {
      console.log(JSON.stringify(error));
      console.log(error);
    }
  }

  return (
    <div
      className={classes.root}
      style={{ margin: 0, padding: 0, height: '95%' }}>
      <Box>
        <div style={{ display: "inline-flex" }}>
          <Button
            onClick={() => onBack()}
            className={classes.button}
            startIcon={<ArrowBackIcon />}
          ></Button>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="simple tabs example">
            <Tab label="Tenant" {...a11yProps(0)} />
            <Tab label="Users" {...a11yProps(1)} />
            <Tab label="API Users" {...a11yProps(2)} />
            <Tab label="MessageTypes" {...a11yProps(3)} />
            <Tab label="Managed Node Types" {...a11yProps(4)} />
            <Tab label="Functions" {...a11yProps(5)} />
            <Tab label="Usage" {...a11yProps(6)} />
          </Tabs>
        </div>
      </Box>
      <TabPanel value={value} index={0}>
        <TenantConfiguration tenant={tenant} currentUserRole={currentUserRole} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <TenantUsers tenant={tenant} currentUserRole={currentUserRole} />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <TenantAPIUsers tenant={tenant} currentUserRole={currentUserRole} />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <TenantMessageTypes tenant={tenant} currentUserRole={currentUserRole} />
      </TabPanel>
      <TabPanel value={value} index={4}>
        <ManagedNodeTypesView tenant={tenant} currentUserRole={currentUserRole} />
      </TabPanel>
      <TabPanel value={value} index={5}>
        <TenantFunctions tenant={tenant} currentUserRole={currentUserRole}/>
      </TabPanel>
      <TabPanel value={value} index={6}>
        <Usages tenant={tenant} currentUserRole={currentUserRole}/>
      </TabPanel>
    </div>
  );
};

export default TenantSettings;
