export class LogEventModel {
  timestamp : string = '';
  message : string = '';
  ingestionTime : string = '';
  logLevel: string = '';

  constructor(timestamp: string, message: string, ingestionTime: string) {
    this.timestamp = timestamp;
    this.ingestionTime = ingestionTime;  

    // [INFO] 2021-12-10T23:11:08.643Z 140670865135424
    const parts = message.replace('I Received a message!!!', '').split(/[ \t]+/);
    if (parts && parts.length > 3) {
      this.logLevel = parts[0].replace('[', '').replace(']', '');
      for(let x = 4; x < parts.length; x++) {
        this.message += parts[x] + ' ';
      }
    } else {
      this.message = message;
    }
  }
}