import { GraphQLData } from "interfaces/graphql-data";
import { ITenant } from "interfaces/tenant";

export class TenantModel implements ITenant, GraphQLData {
  lastEvaluatedKey: string = '';
  name: string = '';
  description: string = '';
  region: string = '';
  active: boolean = false;
  audit: boolean = false;
  subscriptionId: string = '';

  public static create(name: string, description: string, region: string, active: boolean, audit: boolean, subscriptionId: string = '') : TenantModel {
    const t = new TenantModel();
    t.name = name;
    t.description = description;
    t.region = region;
    t.active = active;
    t.audit = audit;
    t.subscriptionId = subscriptionId;
    return t;
  }

  async hydrate(data: any, tenant: ITenant): Promise<void> {
    if (data && data.data && data.data.CreateTenant) {
      const dt = data.data.CreateTenant;
      this.name = dt.name;
      this.region = dt.region;
      this.active = dt.active;
      this.subscriptionId = dt.subscriptionId || '';
    } else if (data && data.data.GetTenant) {
      let tn = data.data.GetTenant
      if (data && data.data.GetTenant.Update) {
        tn = data.data.GetTenant.Update;
      }
      this.name = tn.name;
      this.description = tn.description;
      this.region = tn.region;
      this.active = tn.active;
      this.audit = tn.audit;
      this.subscriptionId = tn.subscriptionId;
    } else {
      throw new Error('data structured passed in does not contain data, data.data, data.data.GetTenant');
    }
  }
}